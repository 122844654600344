export const contactUsLink = 'https://twitter.com/pietrekt_'
export const colors = [
	'#fc9d03',
	'green',
	'#036ffc',
	'#34eba8',
	'#ac6aeb',
	'#0ffc03',
	'#eb6b34',
	'#fcf003',
	'grey',
	'#92eb34',

	'#969de0',

	'#d156bf',
	'#eb3467',
	'#ebdddd',
	'#43eb34',
	'#eb34de',
	'#ebcf34',
	'#cfeb34',
	'#34eb71',
	'#34cfeb',
	'#4334eb',
	'#6e34eb',
	'#9e34eb',
	'#34ebbd',
	'#e0969b'
]
