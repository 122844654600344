import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISwapVolumeByDex } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

const DexSwapVolumePCT = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_dex_swap_volume', true, timeframe)) as ISwapVolumeByDex[]

			const thorswapData = chartData.filter((row) => row.LABEL === 'THORSwap')
			const thorwalletData = chartData.filter((row) => row.LABEL === 'THORWallet')
			const trustwalletData = chartData.filter((row) => row.LABEL === 'Trustwallet')
			const asgardexData = chartData.filter((row) => row.LABEL === 'Asgardex')
			const shapeshiftData = chartData.filter((row) => row.LABEL === 'ShapeShift')
			const othersData = chartData.filter((row) => row.LABEL === 'Others')
			const ledgerData = chartData.filter((row) => row.LABEL === 'Ledger')
			const okxData = chartData.filter((row) => row.LABEL === 'OKX')
			const bitgetData = chartData.filter((row) => row.LABEL === 'Bitget')

			let series1,
				series2,
				series3,
				series4,
				series5,
				series6,
				series7,
				series8,
				series10: {
					x: number
					y: number
				}[] = []
			if (timeframe === '1w' || timeframe === '1d') {
				series1 = thorswapData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series2 = thorwalletData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series3 = trustwalletData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series4 = asgardexData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series5 = shapeshiftData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series6 = othersData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series7 = bitgetData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
				series8 = ledgerData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})

				series10 = okxData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.WEEKLY_PCT_OF_TOTAL }
				})
			} else if (timeframe === '1m') {
				series1 = thorswapData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series2 = thorwalletData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series3 = trustwalletData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series4 = asgardexData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series5 = shapeshiftData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series6 = othersData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series7 = bitgetData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
				series8 = ledgerData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})

				series10 = okxData.map((element: ISwapVolumeByDex) => {
					return { x: new Date(element.DAY).getTime() / 1000, y: element.MONTHLY_PCT_OF_TOTAL }
				})
			}

			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'THORSwap',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[0]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'TrustWallet',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[1]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series8,
					strokeWidth: 2,
					name: 'Ledger',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[2]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series10,
					strokeWidth: 2,
					name: 'OKX',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[3]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'THORWallet',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[4]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'BitgetWallet',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[5]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'ShapeShift',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[6]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'Asgardex',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[7]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'Others',
					type: 'bars',
					seriesGroup: '[usd]',
					color: `${colors[8]}`,
					unitSymbol: '%',
					barGroup: 'positive'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Percentage of the Total Swap Volume by Affiliate"
				chartDesc="Percentage of the total affiliate swap volume on THORChain routed through the presented affiliates (this chart doesn't include volume from non-affiliate swaps).
				The data presented on TrustWallet may not be completely accurate up to 14.06.2023."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="7f7629d7-28e9-4d53-8ea8-9135104e48e8"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasDays={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default DexSwapVolumePCT
