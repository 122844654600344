import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISwapCountByDex } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

const DexSwapCountCumulative = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_swap_count_by_dex', true, timeframe)) as ISwapCountByDex[]

			const thorswapData = chartData.filter((row) => row.LABEL === 'THORSwap')
			const thorwalletData = chartData.filter((row) => row.LABEL === 'THORWallet')
			const trustwalletData = chartData.filter((row) => row.LABEL === 'Trustwallet')
			const asgardexData = chartData.filter((row) => row.LABEL === 'Asgardex')
			const shapeshiftData = chartData.filter((row) => row.LABEL === 'ShapeShift')
			const othersData = chartData.filter((row) => row.LABEL === 'Others')
			const ledgerData = chartData.filter((row) => row.LABEL === 'Ledger')
			const okxData = chartData.filter((row) => row.LABEL === 'OKX')
			const bitgetData = chartData.filter((row) => row.LABEL === 'Bitget')

			const series1 = thorswapData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series2 = thorwalletData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series3 = trustwalletData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series4 = asgardexData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series5 = shapeshiftData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series6 = othersData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series7 = bitgetData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.SWAP_COUNT }
			})
			const series8 = ledgerData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			const series10 = okxData.map((element: ISwapCountByDex) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_SWAP_COUNT }
			})
			setDataSeries([
				{
					data: series1,
					strokeWidth: 2,
					name: 'THORSwap',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[0]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series3,
					strokeWidth: 2,
					name: 'TrustWallet',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[1]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series8,
					strokeWidth: 2,
					name: 'Ledger',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[2]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series10,
					strokeWidth: 2,
					name: 'OKX',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[3]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series2,
					strokeWidth: 2,
					name: 'THORWallet',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[4]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series7,
					strokeWidth: 2,
					name: 'BitgetWallet',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[5]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series5,
					strokeWidth: 2,
					name: 'ShapeShift',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[6]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series4,
					strokeWidth: 2,
					name: 'Asgardex',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[7]}`,
					unitSymbol: '',
					barGroup: 'positive'
				},
				{
					data: series6,
					strokeWidth: 2,
					name: 'Others',
					type: 'bars',
					seriesGroup: '[swap #]',
					color: `${colors[8]}`,
					unitSymbol: '',
					barGroup: 'positive'
				}
			])
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Total Swap Count by Affiliate"
				chartDesc="Cumulated swap count on THORChain routed through the presented affiliates.
				The data presented on TrustWallet may not be completely accurate up to 14.06.2023."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="d16cd8f3-39a4-4789-b5ba-e66179d75412"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default DexSwapCountCumulative
