import React, { SetStateAction, useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ILPUnrealizedPL } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import { colors } from '../../const'
import ChartPage from '../../pages/ChartPage/ChartPage'

const ReserveILPExposure = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChart[] | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1d')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			const chartData = (await getBackendData('thorchain', 'tc_lp_unrealized_pl', true, timeframe)) as ILPUnrealizedPL[]
			const pools = ['BTC.BTC', 'ETH.ETH', 'Stablecoin', 'Others']
			const assetData: ILPUnrealizedPL[][] = []
			const seriesData: { x: number; y: number }[][] = []
			const setSeries: SetStateAction<IChart[] | undefined> = []
			pools.forEach((pool) => {
				assetData.push(chartData.filter((row) => row.ASSET_NAME === pool))
			})
			assetData.forEach((asset) => {
				seriesData.push(
					asset.map((element: ILPUnrealizedPL) => {
						return { x: new Date(element.DAY).getTime() / 1000, y: element.ILP_EXPOSURE_RUNE }
					})
				)
			})
			const series9 = assetData[1].map((element: ILPUnrealizedPL) => {
				return { x: new Date(element.DAY).getTime() / 1000, y: element.TOTAL_ILP_EXPOSURE_RUNE }
			})
			setSeries.push({
				data: series9,
				strokeWidth: 2,
				name: 'TOTAL',
				type: 'line',
				seriesGroup: '[rune]',
				color: `${color}`,
				unitSymbol: 'ᚱ'
			})
			let i = 0
			seriesData.forEach((series) => {
				setSeries.push({
					data: series,
					strokeWidth: 2,
					name: pools[i].split('-')[0].toUpperCase(),
					type: 'line',
					seriesGroup: '[rune]',
					color: colors[i],
					unitSymbol: 'ᚱ'
				})
				i++
			})

			setDataSeries(setSeries)
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Reserve ILP Exposure"
				chartDesc="For every first block of each day all current liquidity providers are taken and their impermanent loss protection is calculated."
				chartDescTitle="Metric Description"
				chartData={dataSeries}
				queryId="80a41d4e-93b7-47e7-b63d-a5f5cf4a7b23"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
				hasLogScale={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default ReserveILPExposure
