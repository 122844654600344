import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ISaversDepth } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'
import { colors } from '../../const'

export async function getSaverDepthsUSDConfig(color: string, inverseColor: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_savers_liquidity_usd', isRefreshing, timeframe)) as ISaversDepth[]
	const btcData = chartData.filter((row) => row.ASSET === 'BTC/BTC')
	const ethData = chartData.filter((row) => row.ASSET === 'ETH/ETH')
	const bchData = chartData.filter((row) => row.ASSET === 'BCH/BCH')
	const avaxData = chartData.filter((row) => row.ASSET === 'AVAX/AVAX')
	const bnbData = chartData.filter((row) => row.ASSET === 'BNB/BNB')
	const dogeData = chartData.filter((row) => row.ASSET === 'DOGE/DOGE')
	const ltcData = chartData.filter((row) => row.ASSET === 'LTC/LTC')
	const atomData = chartData.filter((row) => row.ASSET === 'GAIA/ATOM')
	const stablecoinData = chartData.filter((row) => row.ASSET === 'Stablecoin')

	const series1 = btcData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series2 = ethData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series3 = bchData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series4 = avaxData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series5 = bnbData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series6 = dogeData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series7 = ltcData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series8 = atomData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series10 = stablecoinData.map((element: ISaversDepth) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.CUMULATIVE_DEPTH_USD }
	})
	const series9 = []
	for (let i = 0; i < series1.length; i++) {
		let point = {
			x: series1[i].x,
			y: series1[i].y + series2[i].y + series3[i].y + series4[i].y + series5[i].y + series6[i].y + series7[i].y + series8[i].y + series10[i].y
		}
		series9.push(point)
	}
	const config: IChart[] = [
		{
			data: series9,
			strokeWidth: 2,
			name: 'Total',
			type: 'line',
			seriesGroup: '[usd]',
			color: `${inverseColor}`,
			unitSymbol: '$'
		},
		{
			data: series1,
			strokeWidth: 2,
			name: 'BTC',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[0]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'ETH',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[2]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series10,
			strokeWidth: 2,
			name: 'STABLECOINS',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[1]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series3,
			strokeWidth: 2,
			name: 'BCH',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[3]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series4,
			strokeWidth: 2,
			name: 'AVAX',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[4]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series5,
			strokeWidth: 2,
			name: 'BNB',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[5]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series6,
			strokeWidth: 2,
			name: 'DOGE',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[6]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series7,
			strokeWidth: 2,
			name: 'LTC',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[7]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		},
		{
			data: series8,
			strokeWidth: 2,
			name: 'ATOM',
			type: 'bars',
			seriesGroup: '[usd]',
			color: `${colors[8]}`,
			unitSymbol: '$',
			barGroup: 'usd'
		}
	]
	return { config: config, latestValue: series9[series9.length - 1].y, latestValueDate: series9[series9.length - 1].x }
}

const SaverDepthsUSD = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')
	const InitializeData = async (color: string, inverseColor: string, timeframe: string) => {
		try {
			setDataSeries(await getSaverDepthsUSDConfig(color, inverseColor, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		let inverseMainColor = theme === 'dark' ? 'black' : 'white'
		InitializeData(mainColor, inverseMainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Saver Depths"
				chartDesc="Cumulative depth of the Saver pools denominated in USD (BNB includes both BEP2 and BSC)."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="18545cbf-0d11-47f8-8062-59bae612b709"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				hasHours={false}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default SaverDepthsUSD
