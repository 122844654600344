import React, { useContext, useEffect, useState } from 'react'

import { getBackendData } from '../../api/api'
import { ITCEarningsDistribution } from '../../api/interfaces'
import { ThemeContext } from '../../App'
import { IChart, IChartConfig } from '../../components/Chart/interface'
import LoadingChart from '../../components/LoadingIcon/Loading'
import ChartPage from '../../pages/ChartPage/ChartPage'

export async function getLiqFeesConfig(color: string, isRefreshing: boolean, timeframe: string) {
	const chartData = (await getBackendData('thorchain', 'tc_rune_earnings_distribution', isRefreshing, timeframe)) as ITCEarningsDistribution[]

	const series1 = chartData.map((element: ITCEarningsDistribution) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.LIQUIDITY_FEES_USD }
	})
	const series2 = chartData.map((element: ITCEarningsDistribution) => {
		return { x: new Date(element.DAY).getTime() / 1000, y: element.LIQUIDITY_FEES_USD_CUMULATIVE }
	})
	const config: IChart[] = [
		{
			data: series1,
			strokeWidth: 2,
			name: 'liq fees',
			type: 'bars',
			seriesGroup: '[usd]',
			color: 'green',
			unitSymbol: '$'
		},
		{
			data: series2,
			strokeWidth: 2,
			name: 'liq fees cumulative',
			type: 'line',
			seriesGroup: ' [usd]',
			color: 'orange',
			unitSymbol: '$'
		}
	]
	return { config: config, latestValue: series2[series2.length - 1].y, latestValueDate: series2[series2.length - 1].x }
}
const LiqFees = () => {
	const { setTheme, theme } = useContext(ThemeContext)
	const [dataSeries, setDataSeries] = useState<IChartConfig | undefined>(undefined)
	const [timeframe, setTimeframe] = useState('1w')
	const InitializeData = async (color: string, timeframe: string) => {
		try {
			setDataSeries(await getLiqFeesConfig(color, true, timeframe))
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		let mainColor = theme === 'dark' ? 'white' : 'black'
		InitializeData(mainColor, timeframe)
	}, [theme, timeframe])
	if (dataSeries !== undefined) {
		return (
			<ChartPage
				chartTitle="Liquidity Fees"
				chartDesc="Fees paid by users (swap fees, liq adds/withdrawals, saver adds/withdrawals)."
				chartDescTitle="Metric Description"
				chartData={dataSeries.config}
				queryId="9bda51b5-c3ad-4ab6-abd8-17a004ace38d"
				timeframe={timeframe}
				setTimeframe={setTimeframe}
				// startZoom={1631829600}
				// endZoom={1666476000}
				hasHours={false}
				reverseLegend={true}
			/>
		)
	} else {
		return <LoadingChart />
	}
}

export default LiqFees
